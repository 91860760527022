import { GetSettings } from "../Settings"

/**
 * Función que obtiene los temas del examen en el que se está realizando la búsqueda
 * @param {Object} current
 * @param {int} indexExam
 * @returns array
 */
const GetExamTopics = async (current, indexExam) => {
    try {
        const settings = await GetSettings()

        if (settings === null) {
            return null
        }

        if (settings.courses === undefined || settings.courses === null || settings.courses[settings.current.indexCourse] === undefined || settings.courses[settings.current.indexCourse] === null) {
            return null
        }

        if (settings.courses[settings.current.indexCourse].topics[indexExam].competences === undefined || settings.courses[settings.current.indexCourse].topics[indexExam].competences === null) {
            return null
        }

        if (settings.courses[settings.current.indexCourse].topics[indexExam].competences.length === 0) {
            return null
        }

        const topics = settings.courses[settings.current.indexCourse].topics[indexExam].competences

        const searchResults = topics.map((competence) => {
            if (competence.activity) {
                return {
                    type: 'activity',
                    indexCourse: current.indexCourse,
                    indexTopic: indexExam,
                    indexActivity: competence.activity.indexActivity,
                    title: competence.activity.title
                }
            } else {
                return {
                    type: 'teachingMaterial',
                    indexCourse: current.indexCourse,
                    indexTopic: competence.indexTopic,
                    name: competence.name
                }
            }
        })

        return searchResults
    } catch (error) {
        console.log(error)
    }
}

/**
 * Función que obtiene los resultados sugeridos por la búsqueda realizada en el examen
 * @param {array} examTopics 
 * @param {string} searchTerm 
 * @returns array
 */
const GetSearchResults = async (examTopics, searchTerm) => {
    try {
        return examTopics.filter(topic => {
            for (const key in topic) {
              const value = topic[key]

              if (typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true // Si hay una coincidencia, incluye el elemento en el resultado
              }
            }

            return false // Si no hay coincidencias en ninguna propiedad, no lo incluye
        })
    } catch (error) {
        console.log(error)
    }
}

export { GetExamTopics, GetSearchResults };