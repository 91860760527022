import { GetSettings } from "../../Settings"


//----- Funciones comunes del proyecto ------//
/**
 * Función para aleatorizar las preguntas y opciones (radio)
 * @param {array} array 
 * @returns array
*/
const Randomize = (array) => {
    try {
        // 1. Crear una copia del array original
        const newArray = [...array] // Usamos el operador spread (...) para crear una copia independiente
    
        // 2. Iterar desde el final del array hacia el principio
        for (let i = newArray.length - 1; i > 0; i--) {
            // 3. Generar un índice aleatorio entre 0 y la posición actual (i)
            const randomIndex = Math.floor(Math.random() * (i + 1)) // Math.random() da un número entre 0 (inclusive) y 1 (exclusive)
        
            // 4. Intercambiar el elemento en la posición actual (i) con el elemento en el índice aleatorio (j)
            const temp = newArray[i] // Guardamos el elemento actual en una variable temporal
            newArray[i] = newArray[randomIndex] // Colocamos el elemento aleatorio en la posición actual
            newArray[randomIndex] = temp // Colocamos el elemento actual en la posición aleatoria
        }
    
        // 5. Devolver el array mezclado (la copia)
        return newArray
    } catch (error) {
        console.log(error)
    }
}
//----- Funciones comunes del proyecto ------//



//----- Funciones personalizadas de GetQuiz ------//

const GetCorrectAnswers = ( questions, selectedAnswers ) => {
    try {
        let countCorrect = 0
        //let unansweredQuestions = []
        
        questions.questions.forEach((question, index) => {
        const correctOption = question.options.find(option => option.isCorrect)

        if (selectedAnswers[index] === correctOption?.text) {
            countCorrect++
            // } else if (!selectedAnswers[index]) {
                //     unansweredQuestions.push(index)
            }
        })

        // Enfocar la primera pregunta sin respuesta
        // if (unansweredQuestions.length > 0) {
        //     const firstUnansweredIndex = unansweredQuestions[0]
        //     const firstUnansweredInput = document.querySelector(`input[name="answer-${firstUnansweredIndex}"]`)
            
        //     if (firstUnansweredInput) {
        //         firstUnansweredInput.focus()
        //     }
        // }

        return countCorrect
    } catch (error) {
        console.log(error)
    }
}

//----- Funciones personalizadas de GetQuiz ------//





const GetConcept = async (current) => {
    try {
        const settings = await GetSettings()

        if (settings === null || (settings.material === undefined || settings.material === null)) {
            return null
        }
        
        // Encuentra el curso con el indexCourse dado
        const foundCourse = settings.material.find(item => item.indexCourse === current.indexCourse)

        if (foundCourse && foundCourse.material && foundCourse.material.length > 0) {
            const conceptsArray = foundCourse.material[0].concepts // Accede al array "concepts"
        
            // Encuentra el concepto con el indexTopic dado
            const foundConcept = conceptsArray.find(concept => concept.indexTopic === current.indexTopic)
        
            if (foundConcept) {
                return {
                    content: foundConcept.content,
                    carrousel: foundConcept.carrousel
                }
            }
        }

        return null // Retorna null si no se encuentra el curso o el concepto



        // const course = settings.material

        // console.log('settings.material: ' + JSON.stringify(settings.material))

        // const materialOfCourse = settings.material.find(course => course.indexCourse === current.indexCourse)

        // // if (materialOfCourse.material === undefined || materialOfCourse.material === null || materialOfCourse.material.concepts === undefined || materialOfCourse.material.concepts === null) {
        // //     return null
        // // }

        // console.log('materialOfCourse: ' + materialOfCourse)

        // const conceptsOfCourse = materialOfCourse.material.concepts.find(concept => concept.indexTopic === current.indexTopic)

        // console.log('conceptsOfCourse: ' + conceptsOfCourse)

        // const data = {
        //     content: conceptsOfCourse.content,
        //     carrousel: conceptsOfCourse.carrousel
        // }

        // console.log('data: ' + data)

        // return data


    } catch (error) {
        console.log(error)
    }
}

const GetQuiz = async (current) => {
    try {
        const settings = await GetSettings()

        if (settings === null || (settings.material === undefined || settings.material === null)) {
            return null
        }
        
        // Encuentra el curso con el indexCourse dado
        const foundCourse = settings.material.find(item => item.indexCourse === current.indexCourse)

        if (foundCourse && foundCourse.material && foundCourse.material.length > 0) {
            const quizzesArray = foundCourse.material[1].quizzes // Accede al array "quizzes"
        
            // Encuentra el concepto con el indexTopic dado
            const foundQuiz = quizzesArray.find(quiz => quiz.indexTopic === current.indexTopic)
        
            if (foundQuiz) {
                const randomizedQuestions = Randomize(foundQuiz.questions)
            
                const newRandomizedQuestions = randomizedQuestions.map(question => ({
                    ...question,
                    options: Randomize(question.options)
                }))

                return {
                    questions: newRandomizedQuestions
                }
            }
        }

        return null // Retorna null si no se encuentra el curso o el concepto
    } catch (error) {
        console.log(error)
    }
}

const GetVideos = async (current) => {
    try {
        const settings = await GetSettings()

        if (settings === null || (settings.material === undefined || settings.material === null)) {
            return null
        }
        
        // Encuentra el curso con el indexCourse dado
        const foundCourse = settings.material.find(item => item.indexCourse === current.indexCourse)

        if (foundCourse && foundCourse.material && foundCourse.material.length > 0) {
            const videosArray = foundCourse.material[2].videos // Accede al array "videos"
        
            // Encuentra el concepto con el indexTopic dado
            const foundVideo = videosArray.find(video => video.indexTopic === current.indexTopic)
        
            if (foundVideo) {
                return {
                    urlVideo: foundVideo.urlVideo
                }
            }
        }

        return null // Retorna null si no se encuentra el curso o el concepto
    } catch (error) {
        console.log(error)
    }
}

const GetChallenges = async (current) => {
    try {
        const settings = await GetSettings()

        if (settings === null || (settings.material === undefined || settings.material === null)) {
            return null
        }
        
        // Encuentra el curso con el indexCourse dado
        const foundCourse = settings.material.find(item => item.indexCourse === current.indexCourse)

        if (foundCourse && foundCourse.material && foundCourse.material.length > 0) {
            const challengesArray = foundCourse.material[3].challenges // Accede al array "challenges"
        
            // Encuentra el concepto con el indexTopic dado
            const foundChallenge = challengesArray.find(video => video.indexTopic === current.indexTopic)
        
            if (foundChallenge) {
                // Mezcla las opciones antes de retornar
                const randomizedOptions = Randomize(foundChallenge.options)

                return {
                    instruction: foundChallenge.instruction,
                    options: randomizedOptions
                }
            }
        }

        return null // Retorna null si no se encuentra el curso o el concepto
    } catch (error) {
        console.log(error)
    }
}

export { Randomize, GetCorrectAnswers, GetConcept, GetQuiz, GetVideos, GetChallenges };