import { useState, useEffect } from 'react'

//Componentes personalizados comunes del proyecto
import { ExamplesModal } from './ExamplesModal'

//Funciones personalizadas del proyecto
import { GetConcept } from '../../../../utilities/BL/Viewer/Teaching/Concepts'


const Concepts = ({ current }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState({})
    const [showConfirmModal, setShowConfirmModal] = useState(false)


    const LoadPage = async () => {
        try {
            setPage(await GetConcept(current))
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    }, [])

    useEffect(() => {
        //Función que se ejecuta al presionar la tecla Escape en la página web
        const handleEscapeKeyDown = (event) => {
          if (event.key === 'Escape') {
            if (showConfirmModal) {
                setShowConfirmModal(false)
            }
          }
        }

        window.addEventListener('keydown', handleEscapeKeyDown)
    
        // Limpiar el event listener al desmontar el componente
        return () => {
          window.removeEventListener('keydown', handleEscapeKeyDown)
        }
    }, [])
    
    

    try {
        return (
            isLoading ? (
                <p>Cargando...</p>
            ) : (
                <div className='Concepts'>
                    <div className="Concepts-Content" dangerouslySetInnerHTML={{ __html: page.content }} />
                    
                    <button type='button' className='btn btn-success ps-5 pe-5 pt-2 pb-2 mt-5 d-block m-auto' onClick={() => setShowConfirmModal(true)}>Ejemplos de uso</button>
                    
                    {showConfirmModal && (
                        <ExamplesModal setShowConfirmModal={setShowConfirmModal} carrousel={page.carrousel} />
                    )}
                </div>
            )
        )        
    } catch (error) {
        console.log(error)
    }
}

export { Concepts };


{/*
    <h1>Creación de gráficas</h1>
                
    <p>Las gráficas son elementos visuales que facilitan la comprensión y el análisis de la información en una hoja de cálculo. A través de las gráficas es posible identificar datos de forma intuitiva, a comparación de analizar la información solamente con números.</p>

    <p>Existen varios tipos de gráficas. Las gráficas más comunes son las siguientes:</p>

    <ul>
        <li>
            <p>Gráficas de barras: Representan los datos mediante barras verticales u horizontales en dos o tres dimensiones (2D / 3D).</p>
            <img className='Concepts-Images' src='/img/teaching/concepts/two/1.png' alt='Hoja de cálculo de Excel' />
        </li>
        <li>
            <p>Gráficas circulares, también llamadas de sectores, pastel o rebanadas: Representan proporciones de un todo, mostrando cada elemento como parte del círculo, en dos o tres dimensiones (2D / 3D).</p>
            <img className='Concepts-Images' src='/img/teaching/concepts/two/2.png' alt='Hoja de cálculo de Excel' />
        </li>
        <li>
            <p>Gráficas de líneas: Representan los datos mediante puntos que se conectan de forma consecutiva.</p>
            <img className='Concepts-Images' src='/img/teaching/concepts/two/3.png' alt='Hoja de cálculo de Excel' />
        </li>
    </ul>

    <p>Las gráficas generalmente pueden personalizarse para mejorar el aspecto visual de los elementos en ella. Por ejemplo, el color de fondo o el color de cada segmento, lo cual te permite mostrar la información en la manera que más te guste o que se adapte a tus necesidades.</p>

    <p>Otros tipos de gráfica que puedes encontrar en una hoja de cálculo son las gráficas de dispersión, gráficas de áreas, gráficas radiales y gráficas de superficie. Sin embargo, es importante saber que las gráficas disponibles en una hoja de cálculo pueden variar según la tecnología o aplicación que utilices.</p>
*/}