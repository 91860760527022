import React, { useState } from 'react';

function Button({ type, className, iconButton, iconClass, text, onClick }) {
    let customClassName = className


    // Estado para controlar la clase del ícono
    const [iconButtonClass, setIconButtonClass] = useState('');

    // Función para manejar el clic en el botón
    const handleClick = () => {
        // Alternar la clase del ícono
        setIconButtonClass(prevClass => (prevClass === '' ? 'hideSection' : ''));

        // Ejecutar la función onClick pasada como prop
        if (onClick) {
            onClick();
        }
    };


    return (
        <button type={type} className={customClassName} onClick={handleClick}>
            {/* {iconButton && <i className={iconButton}></i>} */}
            {iconButton && <i className={`d-inline-block ${iconButton} ${iconButtonClass}`}></i>}
            {text ? text : iconClass && <i className={iconClass}></i>}
        </button>
    );
}

export { Button };