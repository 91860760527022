import { ValidateSqlInjection } from './Security'

/**
 * Función que valida la información ingresada en los campos y devuelve un arreglo con los mensajes de error (en caso de haber)
 * @param {Object} formData 
 * @returns {Array}} 
 */
const ValidateForm = (formData) => {
    try {
        // Validación detallada de los campos del formulario
        const errors = {}

        if (formData.id !== undefined) {
            if (!formData.id) {
                errors.id = 'Ingresa el ID'
            } else if (!/^[A-Za-z]{5}-[A-Za-z]{5}$/.test(formData.id)) {
                errors.id = 'El ID que escribiste es inválido'
            } else if (ValidateSqlInjection(formData.id)) {
                errors.id = 'El ID que escribiste es inválido'
            }
        }

        if (formData.user !== undefined) {
            if (!formData.user) {
                errors.user = 'Ingresa tu usuario'
            } else if (ValidateSqlInjection(formData.user)) {
                errors.user = 'El usuario que escribiste es inválido'
            }
        }

        if (formData.password !== undefined) {
            if (!formData.password) {
                errors.password = 'Ingresa tu contraseña'
            } else if (ValidateSqlInjection(formData.password)) {
                errors.password = 'La contraseña que escribiste es inválida'
            }
        }

        return errors
    } catch (error) {
        console.log(error)
    }
}

export { ValidateForm };