import { GetSettings } from "./Settings"


const GetActivity = async (indexCourse, indexTopic, indexActivity) => {
    try {
        const settings = await GetSettings()

        if (settings === null || (settings.courses === undefined || settings.courses === null)) {
            return null
        }

        if (settings.courses[indexCourse] === undefined || settings.courses[indexCourse] === null) {
            return null
        }

        if (settings.courses[indexCourse].topics === undefined || settings.courses[indexCourse].topics === null) {
            return null
        }

        if (settings.courses[indexCourse].topics[indexTopic] === undefined || settings.courses[indexCourse].topics[indexTopic] === null) {
            return null
        }

        if (settings.courses[indexCourse].topics[indexTopic].competences === undefined || settings.courses[indexCourse].topics[indexTopic].competences === null) {
            return null
        }

        // Filtrar el array para encontrar el objeto con el iAct deseado
        const activity = settings.courses[indexCourse].topics[indexTopic].competences.find(competence => competence.activity && competence.activity.indexActivity === indexActivity)

        // Si se encuentra, retornar el objeto 'activity', de lo contrario retornar null
        return activity.activity
    } catch (error) {
        console.log(error)
    }
}

export { GetActivity };