const ExamplesModal = ({ setShowConfirmModal, carrousel }) => {
    try {
        return ( 
            <>
                {/* Área gris */}
                <div className='Concepts-Modal-Background' onClick={() => setShowConfirmModal(false)}>
                    <button type='button' className='Concepts-Modal-ButtonClose' onClick={() => setShowConfirmModal(false)}><i className="bi bi-x"></i></button>
                </div>


                <div className='Concepts-Modal'>
                    <div id="examples" className="carousel slide">
                        <div className="carousel-indicators">
                            {carrousel.map((item, index) => (
                                index === 0 ? (
                                    <button type="button" data-bs-target="#examples" data-bs-slide-to={index} className="active" aria-current="true" aria-label={`Slide ${index + 1}`}></button>
                                ) : (
                                    <button type="button" data-bs-target="#examples" data-bs-slide-to={index} aria-label={`Slide ${index + 1}`}></button>
                                )
                            ))}
                        </div>

                        <div className="carousel-inner">
                            {carrousel.map((image, index) => (
                                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                    <img src={`${image}`} className="d-block w-100" alt="..." />
                                </div>
                            ))}
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#examples" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon bi-caret-left-fill" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#examples" data-bs-slide="next">
                            <span className="carousel-control-next-icon bi-caret-right-fill" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </>
        )
    } catch (error) {
        console.log(error)
    }
}

export { ExamplesModal };