import { DLGetSettings } from './../DL/Settings'

/**
 * Función para obtener las configuraciones de la plataforma
 * @param {string} language 
 * @param {boolean} isLoginPage 
 * @returns 
 */
const GetSettings = async (language = null, isLoginPage = false) => {
    try {
      const settings = await DLGetSettings(language, isLoginPage) // Esperar a que getSettings termine

      if (settings === null) {
        return null
      }
      
      return settings
    } catch (error) {
      console.log(error)
    }
}





const GetMenu = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.menu === undefined || settings.menu === null)) {
      return null
    }

    return settings.menu
  } catch (error) {
    console.log(error)
  }
}





const GetMessages = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.messages === undefined || settings.messages === null)) {
      return null
    }

    return settings.messages
  } catch (error) {
    console.log(error)
  }
}





/**
 * Función que obtiene el tema de las configuraciones del usuario
 * @returns array
 */
const GetTheme = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.theme === undefined || settings.theme === null)) {
      return null
    }

    return settings.theme
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece el nuevo tema en las configuraciones del usuario
 * @param {string} $theme 
 * @returns boolean
 */
const SetTheme = ($theme) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.theme === undefined || settings.theme === null)) {
      return null
    }

    if ($theme === null || $theme === '' || ($theme.toLowerCase() !== 'dark' && $theme.toLowerCase() !== 'light')) {
      return false
    }

    settings.theme = $theme.toLowerCase()
    sessionStorage.setItem('settings',JSON.stringify(settings))
    return true
  } catch (error) {
    console.log(error)
  }
}

// const GetLanguage = () => {
//   try {
//     const settings = DLGetSettings() // Esperar a que getSettings termine
    
//     if (settings === null || (settings.messages === undefined || settings.messages === null)) {
//       return null
//     }

//     return settings.messages
//   } catch (error) {
//     console.log(error)
//   }
// }

export { GetSettings, GetMenu, GetMessages, GetTheme, SetTheme };