import { useEffect, useState } from "react"

//Componentes comunes generales
import { Header } from "../../commons/components/Panel/Header"
import { Loading } from "../../commons/components/Common/Loading"

//Funciones personalizadas del proyecto
import { GetExamResult } from "../../utilities/BL/Students"
import { QuestionDrafting } from './QuestionDrafting'


const Results = ({ beforeView, additionalBeforeView }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [result, setResult] = useState({})

    
    const LoadPage = async () => {
        try {
            setResult(await GetExamResult())
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    }, [])
    


    try {
        return (
            <>    
                <Header beforeView={additionalBeforeView} />

                {isLoading ? (
                    <Loading />
                ) : (
                    result ? (
                        result.message === undefined ? (
                            <main className="Container Results">
                                <button type='button' className='Button-Return' onClick={() => beforeView()}><i className="bi bi-arrow-left"></i></button>
                                
                                <p className="Results-Course">Resultados de: Examen Demo</p>
                                <p className="Results-Score">{`Resultado: ${result.score} puntos`}</p>
                                <p className="Results-TotalQuestions">{`Total de preguntas: ${result.totalQuestions}`}</p>
                                
                                {result.questionsResponse.map((question, index) => (
                                    <div key={index} className={`Results-Question ${question.answer === 0 ? 'Results-Question-Bad' : 'Results-Question-Good'}`}>
                                        {/* <p>Pregunta: {question.drafting.replace('#','')}</p> */}
                                        <QuestionDrafting drafting={question.drafting} />

                                        {question.answer === 0 ? (
                                            <p className="Results-Question-Detail-Bad">{question.detail}</p>
                                        ) : (
                                            <p className="Results-Question-Detail-Good">¡Correcto!</p>
                                        )}
                                    </div>
                                ))}
                            </main>
                        ) : (
                            <main className="Container Results">
                                <button type='button' className='Button-Return' onClick={() => beforeView()}><i className="bi bi-arrow-left"></i></button>

                                {result.message === '' ? (
                                    <p className="Results-Course">No se pudo obtener el resultado del examen. Intenta nuevamente o intenta más tarde.</p>
                                ) : (
                                    <p className="Results-Course">{result.message}</p>
                                )}
                            </main>
                        )
                    ) : (
                        <main className="Container Results">
                            <button type='button' className='Button-Return' onClick={() => beforeView()}><i className="bi bi-arrow-left"></i></button>

                            <p className="Results-Course">No se pudieron obtener los resultados de tu examen. Inténtalo nuevamente o más tarde.</p>
                        </main>
                    )
                )}
            </>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Results };