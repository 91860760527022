import { useEffect } from 'react'


const Exam = ({ beforeView, nextView }) => {

    const LoadExam = () => {
        try {
            // {/* <button onclick="ejecutarAplicacion()">Ejecutar App de Five Digital Minds</button>

            // <script>
            //     function ejecutarAplicacion() {
            //         try {
            //             window.location.href = "fdmapp://"; 
            //         } catch (error) {
            //             alert("No se pudo ejecutar la aplicación. Asegúrate de que esté instalada y correctamente configurada.");
            //             // O puedes redirigir al usuario a una página de descarga:
            //             // window.location.href = "https://www.tusitio.com/descarga-app";
            //         }
            //     }
            // </script> */}

            window.location.href = "fdmapp://"
        } catch (error) {
            //console.log(error)
            alert("No se pudo ejecutar la aplicación. Asegúrate de que esté instalada y correctamente configurada.")
        }
    }

    useEffect(() => {
        LoadExam()
    }, [])


    
    try {
        return (
            <main className="Container Container-FullHeight Exams">
                <button type='button' className='Button-Return' onClick={() => beforeView()}><i className="bi bi-arrow-left"></i></button>

                <div className="Exams-Container">
                    <i className="bi bi-file-earmark-spreadsheet Exams-Container-Icon"></i>
                    <p className="Exams-Container-Title">Iniciando Examen Demo</p>

                    <span className="Exams-Container-Separator"></span>

                    <p className="Exams-Container-Text">Cuando finalices tu examen, aquí podrás ver tus resultados:</p>
                    <button type="button" className="btn btn-success d-block m-auto" onClick={() => nextView()}><i class="bi bi-list-check"></i> Ver resultados del examen</button>
                </div>
            </main>
        )     
    } catch (error) {
        console.log(error)
    }
}

export { Exam };