/**
 * Función para detectar inyección SQL (ejemplo básico)
 * @param {string} value 
 * @returns 
 */
const ValidateSqlInjection = (value) => {
    try {    
        const sqlInjectionKeywords = ['SELECT', 'INSERT', 'UPDATE', 'DELETE', 'DROP', ';', '--']
        
        return sqlInjectionKeywords.some(keyword => value.toUpperCase().includes(keyword))
    } catch (error) {
        console.log(error)
    }
}

export { ValidateSqlInjection };