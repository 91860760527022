import { GetTheme, SetTheme } from "./Settings"


const SetDefaultTheme = () => {
    try {
        const theme = GetTheme()

        if (theme.toLowerCase() === 'light') {
            document.body.classList.remove('DarkTheme')
            return theme.toLowerCase()
        } else if (theme.toLowerCase() === 'dark') {
            document.body.classList.add('DarkTheme')
            return theme.toLowerCase()
        } else {
            //Establecer como predeterminado el tema Blanco en caso de no coincidir con alguno
            document.body.classList.remove('DarkTheme')
            return theme.toLowerCase()
        }
    } catch (error) {
      console.log(error)
    }
}

const ChangeTheme = (theme) => {
    try {
        if (theme.toLowerCase() === 'light') {
            document.body.classList.add('DarkTheme')
            SetTheme('dark')
            return 'dark'
        } else if (theme.toLowerCase() === 'dark') {
            document.body.classList.remove('DarkTheme')
            SetTheme('light')
            return 'light'
        } else {
            //Establecer como predeterminado el tema Blanco en caso de no coincidir con alguno
            SetTheme('light')
            return 'light'
        }
    } catch (error) {
      console.log(error)
    }
}

export { SetDefaultTheme, ChangeTheme };