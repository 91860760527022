import React, { useState, useEffect } from 'react'

//Funciones personalizadas
import { GetQuickAccessCompetences, GetCourses, GetExamResult } from './../../../utilities/BL/Students'

//Componentes comunes generales
import { Header } from './../../../commons/components/Panel/Header'
import { Loading } from './../../../commons/components/Common/Loading'
// import { Menu } from './../../../commons/components/Menu/Menu'
// import { MenuDown } from './../../../commons/components/Menu/MenuDown'

//Componentes comunes del proyecto
import { Section } from './../Section'
//import { TilePanel } from './../TilePanel'

//import { TileEducator } from './../Educator/Tile'
//import { TileSMEducator } from './../Educator/TileSm'

import { TileStudent } from './Tile'
import { TileSMStudent } from './TileSm'



function SDashboard({ beforeView, nextView, additionalNextView, anotherNextView, current, setCurrent, result, setResult, isFirstTimeToTakeExam }) {
    const [isLoading, setIsLoading] = useState(true) // Estado qude carga
    const [tileStudentElements, setTileStudentElements] = useState({})
    const [tileStudentSMElements, setTileStudentSMElements] = useState({})
    

    const LoadPage = async () => {
        try {
            // const tmpTileStudentElements = await GetQuickAccessCompetences()
            // setTileStudentElements(tmpTileStudentElements)
 
            // const tmpTileStudentSMElements = await GetCourses()
            // setTileStudentSMElements(tmpTileStudentSMElements)

            setTileStudentElements(await GetQuickAccessCompetences())
            
            setTileStudentSMElements(await GetCourses())

            setResult(await GetExamResult())
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false) // Marcar la carga como finalizada
        }
    }

    /**
     * Función para obtener la configuración, una vez que termine de cargarse el componente
     */
    useEffect(() => {
        LoadPage()
    }, []) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar


    //Falta completar esto 
    //Si es la primera vez, que destaque el primer elemento rápido (en caso de haber) o curso
    //Si ya terminó el curso y no hay elementos rápidos, no destarcar algo

    return (
        <>
            <Header beforeView={beforeView} />

            {isLoading ? (
                <Loading />
            ) : (
                <main className='Container SDashboard'>
                    {tileStudentElements && tileStudentElements.length > 0 && (
                        <Section textSection={'Continúa donde te quedaste Fernando'}>
                            {/* <TilePanel elements={tileStudentElements} Component={TileStudent} nextView={nextView} indexCourse={indexCourse} indexTopic={indexTopic} /> */}
                            <TileStudent elements={tileStudentElements} nextView={additionalNextView} setCurrent={setCurrent} />
                        </Section>
                    )}

                    {tileStudentSMElements && tileStudentSMElements.length > 0 && (
                        <Section textSection={'Mis cursos'} classNameSection={'Section-Separation'}>
                            {/* <TilePanel elements={tileStudentSMElements} Component={TileSMStudent} nextView={nextView} setCourse={setCourse} setTopic={setTopic} /> */}
                            <TileStudent elements={tileStudentSMElements} nextView={nextView} setCurrent={setCurrent} />
                        </Section>
                    )}

                    {/* {tileStudentSMElements && tileStudentSMElements.length > 0 && (
                        <Section textSection={'Mis cursos'} classNameSection={'Section-Separation'}>
                            {/ <TilePanel elements={tileStudentSMElements} Component={TileSMStudent} nextView={nextView} setCourse={setCourse} setTopic={setTopic} /> /}
                            <TileSMStudent elements={tileStudentSMElements} nextView={nextView} setCurrent={setCurrent} />
                        </Section>
                    )} */}

                    {!isFirstTimeToTakeExam && (result && result.message === undefined) && (
                        <Section textSection={'Mis resultados'} classNameSection={'Section-Separation'}>
                            <TileStudent isResult={true} nextView={anotherNextView} />
                        </Section>
                    )}
                </main>
            )}
            {/* <MenuDown />
            <Menu /> */}
        </>
    )
}

export { SDashboard };





//--- Página Dashboard ---//
// <>
//   <Dashboard />
//   {/* <Header schoolId={"ZZZZ-ZZZZ"} schoolName={"Nombre del instituto"} />

//   <main className='container mt-5'>
//     <div className='row'>
//       {/* Educador 
//         Propiedades:
//         dismiss = Opción booleana que indica si se debe mostrar el botón que permite Descartar o Quitar la sección */}
//       {/* <Section textSection={'Acciones rápidas'} dismiss={true}>
//         <TilePanel elements={tileEducatorElements} Component={TileEducator} />
//       </Section>
//       <Section textSection={'Favoritos'} classNameSection={'mt-5'} dismiss={true}>
//         <TilePanel elements={tileEducatorElements1} Component={TileEducator} />
//       </Section>
//       <Section textSection={'Administración'} classNameSection={'mt-5'}>
//         <TilePanel elements={tileEducatorSMElements} Component={TileSMEducator} />
//       </Section> */}

//       {/* Estudiante */}
//       {/* <Section textSection={'Continúa donde te quedaste'}>
//         <TilePanel elements={tileStudentElements} Component={TileStudent} />
//       </Section>
//       <Section textSection={'Mis cursos'} classNameSection={'mt-5'}>
//         <TilePanel elements={tileStudentSMElements} Component={TileSMStudent} />
//       </Section> /}
//     </div>
//   </main>

//   <MenuDown />
//   <Menu /> */}
// </>



// //--- Página Dashboard ---//
// import { Dashboard } from './components/Dashboard/Dashboard'
// import { Header } from './../../../../Commons/components/Panel/Header'

// import { Section } from './components/Panel/Section'
// import { TilePanel } from './components/Panel/TilePanel'
// import { TileEducator } from './components/Panel/Educator/Tile'
// import { TileSMEducator } from './components/Panel/Educator/TileSm'

// import { TileStudent } from './components/Panel/Student/Tile'
// import { TileSMStudent } from './components/Panel/Student/TileSm'

// import { Menu } from './../../../../Commons/components/Menu/Menu'
// import { MenuDown } from './../../../../Commons/components/Menu/MenuDown'
// import { MenuDown } from './../../' './../../../../Commons/components/Menu/MenuDown'
// //import { info } from 'sass'
// //--- Página Dashboard ---//