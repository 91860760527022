import { useEffect, useState } from "react"

//Funciones comunes generales
import { SetDefaultTheme, ChangeTheme } from "../../../utilities/BL/Header"



const Header = ({ beforeView }) => {
    const [theme, setTheme] = useState('light')

    //Falta completar esto
    //Obtener la info de la institución y directo de la info
    //Obtener esta info desde las Settings
    const schoolId = ''
    const schoolName = 'Fernando Vázquez'

    const ChangeTheTheme = () => {
        try {
            setTheme(ChangeTheme(theme))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        //Establecer el tema
        setTheme(SetDefaultTheme())
    }, []) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar



    return (
        <header className="container Header">
            {/* <p className="schoolId">{schoolId}</p> */}
            <div className="schoolBrand">
                <img src="./img/brand/Five-Digital-Minds-Logotipo-V.png" className="schoolImage" />
                <span>Five Digital Minds</span>
            </div>

            <p className="schoolName text-center">{schoolName}</p>

            <div className="btn-group Header-Menu">
                <button type="button" className="dropdown-toggle Header-Button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-person-circle"></i>
                </button>
                
                <ul className="dropdown-menu dropdown-menu-end">
                    <li><button className="dropdown-item" type="button" onClick={() => ChangeTheTheme()}>
                    {theme === 'dark' ? (
                        <>
                            <i className="bi bi-sun"></i> Tema claro
                        </>
                    ) : (
                        <>
                            <i className="bi bi-moon"></i> Tema oscuro
                        </>
                    )}
                    </button></li>
                    <li><button className="dropdown-item" type="button" onClick={() => beforeView()}><i className="bi bi-logout"></i> Cerrar sesión</button></li>
                </ul>
            </div>
        </header>
    )
}

export { Header };