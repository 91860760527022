import { GetSettings } from './../BL/Settings'

/**
 * Función que obtiene la información del curso que se eligió visualizar
 * @returns array
 */
const GetCourse = async () => {
    try {
        const settings = await GetSettings()

        if (settings === null || (settings.current === undefined || settings.current === null) || (settings.current.indexCourse === undefined || settings.current.indexCourse === null || settings.current.indexCourse === '')) {
            return null
        }

        if (settings === null || (settings.courses === undefined || settings.courses === null)) {
            return null
        }

        if (settings.courses[settings.current.indexCourse] === undefined || settings.courses[settings.current.indexCourse] === null) {
            return null
        }

        const course = settings.courses[settings.current.indexCourse]
        
        return {
            indexCourse: settings.current.indexCourse,
            tileClassName: course.tileClassName,
            tileIconClass: course.tileIconClass,
            typeCourse: course.typeCourse,
            nameCourse: course.nameCourse,
            level: course.level,
            percentageCompleted: course.percentageCompleted,
            activeElementClass: course.activeElementClass,
            topics: course.topics
        }
    } catch (error) {
        console.log(error)
    }
}


const GetCourses = async () => {
    try {
      const settings = await GetSettings()
  
      if (settings && settings.courses) {
        const courses = settings.courses.map((course, index) => {
          return {
            indexCourse: index,
            tileIconClass: course.tileIconClass,
            typeCourse: course.typeCourse,
            nameCourse: course.nameCourse,
            percentageCompleted: course.percentageCompleted
          }
        })
  
        return courses
      } else {
        return null
      }
    } catch (error) {
      console.log(error)
    }
  }

export { GetCourse, GetCourses };