import { SetTopicToShow, SetActivityToShow } from "../../utilities/BL/Students"


const Competence = ({ indexCourse, indexTopic, infoCompetence, nextView, setCurrent }) => {
    
    const ShowTopic = async (indexCourse, indexExam, indexTopic) => {
        try {
            //console.log(`Curso: ${indexCourse} - Examen: ${indexExam} - Tema: ${indexTopic}`)
            //Establecer el tema a mostrar
            setCurrent(await SetTopicToShow(indexCourse, indexExam, indexTopic))
            nextView()
        } catch (error) {
            console.log(error)
        }
    }

    const ShowActivity = async (indexCourse, indexTopic, indexActivity) => {
        try {
            //console.log(`Curso: ${indexCourse} - Tema: ${indexTopic} - Actividad: ${indexActivity}`)
            //Establecer la actividad a mostrar
            setCurrent(await SetActivityToShow(indexCourse, indexTopic, indexActivity))
            nextView()
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <>
            {infoCompetence.activity === undefined || infoCompetence.activity === null ? (
                <li className='Competences-Item' onClick={() => ShowTopic(indexCourse, indexTopic, infoCompetence.indexTopic)}>{infoCompetence.name} <i className='bi bi-box-arrow-in-up-right'></i></li>
            ) : (    
                <li className='Competences-Activity' onClick={() => ShowActivity(indexCourse, indexTopic, infoCompetence.activity.indexActivity)}>{infoCompetence.activity.title} <i className='bi bi-box-arrow-in-up-right'></i></li>
            )}
        </>
    )
}

export { Competence };