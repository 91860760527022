import { useState } from "react"


//Funciones comunes generales
import { ValidateSqlInjection } from './../../commons/components/BL/Security'

//Funciones personalizadas del proyecto
import { GetExamTopics, GetSearchResults } from "../../utilities/BL/Course/SearchInput"
import { SetActivityToShow, SetIndexCourseAndTopic } from "../../utilities/BL/Students"


const SearchInput = ({ placeholder, customClassName, current, indexExam, setCurrent, nextView }) => {
    const [showResults, setShowResults] = useState(false)
    const [searchResults, setSearchResults] = useState({})
    const [examTopics, setExamTopics] = useState(null)


    const ToggleSearchResults = async (e) => {
        try {
            if (e.target.value.toString().trim() !== '') { //&& e.target.value.length > 1
                if (!ValidateSqlInjection(e.target.value.toString().trim())) {
                    if (examTopics === null) {
                        setExamTopics(await GetExamTopics(current, indexExam))
                    }
                    
                    if (examTopics !== null && examTopics.length > 0) {
                        setSearchResults(await GetSearchResults(examTopics, e.target.value.toString().trim()))
                        setShowResults(true)
                    }
                } else {
                    if (showResults) {
                        setShowResults(false)
                    }
                }
            } else {
                if (showResults) {
                    setShowResults(false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const GoToResult = async (result) => {
        try {
            if (result.type === 'teachingMaterial') {
                //console.log(`result.indexCourse: ${result.indexCourse} - result.indexTopic: ${result.indexTopic}`)
                setCurrent(await SetIndexCourseAndTopic(result.indexCourse, result.indexTopic))
                nextView()
            } else if (result.type === 'activity') {
                //console.log(`result.indexCourse: ${result.indexCourse} - result.indexTopic: ${result.indexTopic} - result.indexActivity: ${result.indexActivity}`)
                setCurrent(await SetActivityToShow(result.indexCourse, result.indexTopic, result.indexActivity))
                nextView()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const DisableEnterKey = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    }
    
    

    try {
        return (
            <div className={customClassName}>
                <input type='search' placeholder={placeholder} className={`SearchInput`} onChange={ToggleSearchResults} onKeyDown={DisableEnterKey} />

                <ul id='SearchResults' className={`SearchResults ${showResults ? 'ShowSearchResults' : ''}`}>
                    {searchResults && searchResults.length > 0 ? (
                        <>
                            <p className="SearchResults-Title">Sugerencias para tu búsqueda</p>

                            {searchResults.map((result, index) => (
                                <li key={index} className="SearchResults-Item" onClick={() => GoToResult(result)}>
                                    <span className="SearchResults-Item-Container">
                                        <i className="bi bi-search"></i>
                                        <span className="SearchResults-Text">{result.name ? result.name : result.title}</span>
                                        <span className="SearchResults-Type">{result.type === 'activity' ? 'Actividad' : ''}</span>
                                    </span>
                                    <i className="bi bi-arrow-right"></i>
                                </li>
                            ))}
                        </>
                    ) : (
                        <p className="SearchResults-Title">No hay resultados para esta búsqueda</p>
                    )}
                </ul>
            </div>
        )
    } catch (error) {
        console.log(error)
    }
}

export { SearchInput };