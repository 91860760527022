import { useEffect, useState } from "react"

//Funciones personalizadas del proyecto
import { GetActivity } from "../../../utilities/BL/Activity"


const Activity = ({ current, beforeView }) => {
    const [activity, setActivity] = useState({})
    const [isLoading, setIsLoading] = useState(true)


    const LoadPage = async () => {
        try {
            const tmpActivity = await GetActivity(current.indexCourse, current.indexTopic, current.indexActivity)

            if (tmpActivity === null) {
                return
            }

            setActivity(tmpActivity)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar



    try {
        return (
            <>
                {isLoading ? (
                    <p>Cargando...</p>
                ) : (
                    <>
                        <div className="Container">
                            <button type='button' className='Button-Return mt-4' onClick={() => beforeView()}><i className="bi bi-arrow-left"></i></button>
                        </div>

                        <div className="Container Activity">
                            <div className="Activity-Instructions">
                                <p className="Activity-Instructions-Title">{activity.title}</p>

                                <p className="Activity-Instructions-Text">Descarga la hoja de cálculo de este ejercicio para poner en práctica lo aprendido. Realiza lo siguiente:</p>

                                {activity.steps.length > 0 && (
                                    <ol className="Activity-Instructions-Steps">
                                        {activity.steps[0].map((step, index) => (
                                            <li key={index} className="Activity-Instructions-Steps-Step">{step}</li>
                                        ))}
                                    </ol>
                                )}

                                <p className="Activity-Instructions-Text">Si tienes alguna duda sobre cómo desarrollar algunos de los puntos, puedes consultar los elementos de aprendizaje que se encuentran en cada uno de los temas.</p>
                            </div>

                            <div className="Activity-Files">
                                <p className="Activity-Files-Title">Archivo del ejercicio</p>

                                <a className="Activity-Files-Download" href={activity.urlDownloadFile} download>
                                    {/* <img className="Activity-Files-Download-Image" src="/img/activity/excel.png" alt="Ícono de descarga" /> */}
                                    <i class="bi bi-file-earmark-spreadsheet Activity-Files-Download-Image"></i>
                                    <span className="Activity-Files-Download-Text"><i class="bi bi-download"></i> Descargar ahora</span>
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Activity };