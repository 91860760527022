import { useState } from 'react'


//Componentes personalizados del proyecto
import { Concepts } from './Concepts/Concepts'
import { Quizzes } from './Quizzes/Quizzes'
import { Videos } from './Videos/Videos'
import { Challenges } from './Challenges/Challenges'

//Componentes comunes generales
import { GoToTop } from '../../../commons/components/Common/GoToTop'


const Teaching = ({ current, beforeView }) => {
    const [activeComponent, setActiveComponent] = useState('Concept') // Estado para controlar el componente activo
    
    try {
        return (
            <>
                <main className="Container Container-FullHeight Teaching">
                    <button type='button' className='Button-Return' onClick={() => beforeView()}><i className="bi bi-arrow-left"></i></button>
                    
                    <div className='Teaching-Container'>
                        {activeComponent === 'Concept' && <Concepts current={current} /> }
                        {activeComponent === 'Quiz' && <Quizzes current={current} />}
                        {activeComponent === 'Video' && <Videos current={current} />}
                        {activeComponent === 'Challenge' && <Challenges current={current} />}
                    </div>
                </main>

                <aside className='Teaching-Menu'>
                    <div className='Teaching-Menu-Container'>
                        <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Concept' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Concept')}>Concepto</button>
                        <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Quiz' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Quiz')}>Cuestionario</button>
                        <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Video' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Video')}>Video</button>
                        <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Challenge' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Challenge')}>Desafío</button>
                    </div>
                </aside>

                <GoToTop />
            </>
        )        
    } catch (error) {
        console.log(error)
    }
}

export { Teaching };