import { React, useEffect, useState } from 'react'


//--- Estilos e íconos de Bootstrap ---//
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './styles/styles.css'
//--- Estilos e íconos de Bootstrap ---//


//--- Página Login ---//
import { Login } from './components/Login/Login'
//--- Página login ---//

//--- Página Dashboard ---//
import { SDashboard } from './components/Dashboard/Student/SDashboard'
//--- Página Dashboard ---//

//--- Página Dashboard ---//
import { Course } from './components/Course/Course'
//--- Página Dashboard ---//

//--- Página Viewer ---//
import { Viewer } from './components/Viewer/Viewer'
//--- Página Viewer ---//

//--- Página Results ---//
import { Results } from './components/Results/Results'
//--- Página Results ---//



function App() {
  //Propiedades:
  //activeElementClass = Establece los estilos que lleva el Tile al establecerlo como activo
  //isFavorite = Establece si el elemento debe mostrarse visualmente marcado como favorito

  //Educador
  // const tileEducatorElements = [
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Agendar evaluación al grupo "Excel Academic 2 A"', activeElementClass: 'DegradedExcel' },
  //   { tileClassName: 'ms-3', tileIconClass: 'bi bi-graph-up-arrow', tileText: ' Ver resultados del grupo "Excel Academic 2 A"' },
  //   { tileClassName: 'ms-3', tileIconClass: 'bi bi-person-add', tileText: ' Agregar alumnos al grupo "Excel Academic 2 A"' }
  // ]

  // const tileEducatorElements1 = [
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Agendar evaluación', isFavorite: true },
  //   { tileClassName: 'ms-3', tileIconClass: 'bi bi-graph-up-arrow', tileText: ' Informe en tiempo real', isFavorite: true },
  //   { tileClassName: 'ms-3', tileIconClass: 'bi bi-person-add', tileText: ' Importar alumnos', isFavorite: true }
  // ]

  // const tileEducatorSMElements = [
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Administrar evaluaciones ' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Administrar grupos' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Administrar a mis alumnos' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Progreso de mis alumnos' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Ver resultados' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Informe en tiempo real' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Ver temarios' }
  // ]




  // Obtén los enlaces para un idioma específico, por ejemplo, "spa"
  //const infoCourse = Course.english
  
  // const changeLanguage = async (language = null, isLoginPage = false) => {
  //   try {
  //     const newSettings = await getSettings(language, isLoginPage) // Esperar a que getSettings termine
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // /**
  //  * Función para obtener la configuración, una vez que termine de cargarse el componente
  //  */
  // useEffect(() => {
  //   // Cargar configuración al montar el componente (carga inicial)
  //   changeLanguage(null, true)
  // },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar

  const [activeComponent, setActiveComponent] = useState('Login') // Estado para controlar el componente activo
  const [current, setCurrent] = useState({})
  const [result, setResult] = useState({})
  const [isFirstTimeToTakeExam, setIsFirstTimeToTakeExam] = useState(true)
  

  //--- Funciones para mostrar u ocultar los componentes principales ---//
  const ShowLoginView = () => {
    setActiveComponent('Login')
  }

  const ShowDashboardView = () => {
    setActiveComponent('Dashboard')
  }

  const ShowCourseView = () => {
    setActiveComponent('Course')
  }

  const ShowViewerView = () => {
    setActiveComponent('Viewer')
  }

  const ShowResultsView = () => {
    setActiveComponent('Results')
  }
  //--- Funciones para mostrar u ocultar los componentes principales ---//

  
  useEffect(() => {
    document.getElementById('root').classList.add('BodyComplete')
  }, [])


  try {
    return (
      <>
        {/* //--- Página login ---// */}
        {activeComponent === 'Login' && <Login nextView={ShowDashboardView} setIsFirstTimeToTakeExam={setIsFirstTimeToTakeExam} />}

        {/* //--- Página Dashboard ---// */}
        {activeComponent === 'Dashboard' && <SDashboard beforeView={ShowLoginView} nextView={ShowCourseView} additionalNextView={ShowViewerView} anotherNextView={ShowResultsView} current={current} setCurrent={setCurrent} result={result} setResult={setResult} isFirstTimeToTakeExam={isFirstTimeToTakeExam} />}

        {/* //--- Página Course ---//*/}
        {activeComponent === 'Course' && <Course beforeView={ShowDashboardView} additionalBeforeView={ShowLoginView} nextView={ShowViewerView} current={current} setCurrent={setCurrent} result={result} isFirstTimeToTakeExam={isFirstTimeToTakeExam} setIsFirstTimeToTakeExam={setIsFirstTimeToTakeExam} />}

        {/* //--- Página Viewer ---//*/}
        {activeComponent === 'Viewer' && <Viewer beforeView={ShowCourseView} nextView={ShowResultsView} additionalNextView={ShowLoginView} current={current} />}

        {/* //--- Página Results ---//*/}
        {activeComponent === 'Results' && <Results beforeView={ShowDashboardView} additionalBeforeView={ShowLoginView} />}
      </>
    )
  } catch (error) {
      console.log(error)
  }
}

export default App;