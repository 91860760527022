import { GetSettings } from './../BL/Settings'


const GetQuickAccessCompetences = async () => {
  try {
    const settings = await GetSettings()
    
    if (settings === null || (settings.quickAccess === undefined || settings.quickAccess === null) || (settings.courses === undefined || settings.courses === null)) {
      return null
    }

    const quickAccess = settings.quickAccess
    const courses = settings.courses

    const competences = quickAccess.map(access => {
      if (access.type.toLowerCase() === 'teachingMaterial'.toLowerCase()) {
        const course = courses[access.indexCourse]

        if (course) {
          // Encontrar el tema que contiene la competencia
          let competence = null
          
          for (const topic of course.topics) {
            if (access.indexTopic < topic.competences.length) {
              competence = topic.competences[access.indexTopic].name
              break // Salir del bucle una vez encontrada la competencia
            } else {
              access.indexTopic -= topic.competences.length // Restar el número de competencias del tema actual
            }
          }
          
          return {
            type: access.type,
            indexCourse: access.indexCourse,
            indexExam: access.indexExam,
            indexTopic: access.indexTopic,
            tileClassName: course.tileClassName,
            tileIconClass: course.tileIconClass,
            typeCourse: course.typeCourse,
            nameCourse: course.nameCourse,
            competence: competence || 'Competencia no encontrada', // Si no se encuentra, mostrar un mensaje
            activeElementClass: course.activeElementClass || null // Si no existe, asignar null
          }
        } else {
          return null
        }
        
      } else if (access.type.toLowerCase() === 'activity'.toLowerCase()) {
        //Falta completar esto
        return null
      } else if (access.type.toLowerCase() === 'exam'.toLowerCase()) {
        //Falta completar esto
        return null
      }
    })
    return competences
  } catch (error) {
      console.log(error)
  }
}

const GetCourses = async () => {
  try {
    const settings = await GetSettings()

    if (settings && settings.courses) {
      const courses = settings.courses.map((course, index) => {
        return {
          type: 'course',
          indexCourse: index,
          tileIconClass: course.tileIconClass,
          typeCourse: course.typeCourse,
          nameCourse: course.nameCourse,
          percentageCompleted: course.percentageCompleted
        }
      })

      return courses
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const GetExamResult = async () => {
  try {
    const response = await fetch('http://72.167.49.175:8080/api/getAllResults')

    if (response.ok) {
      const jsonData = await response.json()
      return jsonData // Guardar la información en el estado

    } else if (response.status === 404) { // Manejar específicamente el código 404
      const errorData = await response.json()
      return { message: errorData.message || "Error al intentar obtener la info. Inténtalo nuevamente o más tarde." } // Usar el mensaje de error de la API o uno genérico

    } else {
      // Manejar otros códigos de respuesta (por ejemplo, 404, 500, etc.)
      return {
        message: "Error al intentar obtener la info. Inténtalo nuevamente o más tarde."
      }
    }
  } catch (error) {
    console.log(error)
  }
}





/**
 * Función que establece el índice del curso y tema a visualizar en el JSON de datos (Propiedad current)
 * @param {int} indexCourse 
 * @param {int} indexTopic
 * @returns array
 */
const SetIndexCourseAndTopic = async (indexCourse, indexTopic) => {
  try {
      const settings = await GetSettings()

      // if (settings === null || (settings.current === undefined || settings.current === null)) {
      //     return false
      // }

      if (settings === null) {
        return {}
      }

      // settings.current.type = 'teachingMaterial'
      // settings.current.indexCourse = indexCourse.toString()
      // settings.current.indexTopic = indexTopic.toString()
      
      settings.current = {
        type: 'teachingMaterial',
        indexCourse: indexCourse.toString(),
        indexTopic: indexTopic.toString()
      }

      sessionStorage.setItem('settings', JSON.stringify(settings))
      return settings.current
  } catch (error) {
      console.log(error)
  }
}

/**
 * Función que establece el índice del curso, examen y tema a visualizar en el JSON de datos (Propiedad current)
 * @param {int} indexCourse 
 * @param {int} indexExam 
 * @param {int} indexTopic
 * @returns array
 */
const SetTopicToShow = async (indexCourse, indexExam, indexTopic) => {
  try {
      const settings = await GetSettings()

      // if (settings === null || (settings.current === undefined || settings.current === null)) {
      //     return false
      // }

      if (settings === null) {
        return {}
      }

      // settings.current.type = 'teachingMaterial'
      // settings.current.indexCourse = indexCourse.toString()
      // settings.current.indexTopic = indexTopic.toString()
      
      settings.current = {
        type: 'teachingMaterial',
        indexCourse: indexCourse.toString(),
        indexExam: indexExam.toString(),
        indexTopic: indexTopic.toString()
      }

      sessionStorage.setItem('settings', JSON.stringify(settings))
      return settings.current
  } catch (error) {
      console.log(error)
  }
} 

/**
 * Función que establece el índice del curso y actividad a visualizar en el JSON de datos (Propiedad current)
 * @param {int} indexCourse 
 * @param {int} indexActivity 
 * @returns boolean
 */
const SetActivityToShow = async (indexCourse, indexTopic, indexActivity) => {
  try {
      const settings = await GetSettings()

      if (settings === null) {
        return {}
      }

      // settings.current.type = 'activity'
      // settings.current.indexCourse = indexCourse.toString()
      // settings.current.indexActivity = indexActivity.toString()

      settings.current = {
        type: 'activity',
        indexCourse: indexCourse.toString(),
        indexTopic: indexTopic.toString(),
        indexActivity: indexActivity.toString()
      }

      sessionStorage.setItem('settings', JSON.stringify(settings))
      return settings.current
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece el índice del curso y examen a visualizar en el JSON de datos (Propiedad current)
 * @param {int} indexCourse 
 * @param {int} indexExam 
 * @returns boolean
 */
const SetExamToShow = async (indexCourse, indexExam) => {
  try {
      const settings = await GetSettings()

      if (settings === null) {
        return false
      }

      // settings.current.type = 'exam'
      // settings.current.indexCourse = indexCourse.toString()
      // settings.current.indexExam = indexExam.toString()

      settings.current = {
        type: 'exam',
        indexCourse: indexCourse.toString(),
        indexExam: indexExam.toString()
      }

      sessionStorage.setItem('settings', JSON.stringify(settings))
      return settings.current
  } catch (error) {
    console.log(error)
  }
}

export { GetQuickAccessCompetences, GetCourses, GetExamResult, SetIndexCourseAndTopic, SetTopicToShow, SetActivityToShow, SetExamToShow };