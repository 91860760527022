import { useEffect, useState } from "react"

//Funciones personalizadas para el proyecto
import { GetChallenges } from "../../../../utilities/BL/Viewer/Teaching/Concepts"

//Componente comunes generales
import { Loading } from './../../../../commons/components/Common/Loading'


const Challenges = ({ current }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [quizData, setQuizData] = useState(null)
    const [isCorrect, setIsCorrect] = useState(null) // null (sin respuesta), true (correcto), false (incorrecto)
    const [readyToEvaluate, setReadyToEvaluate] = useState(false)
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null) // Nuevo estado para la opción seleccionada


    const Validate = (isCorrect, index) => {
        try {
            setReadyToEvaluate(true)
            setIsCorrect(isCorrect)
            setSelectedOptionIndex(index) // Actualizar la opción seleccionada
        } catch (error) {
            console.log(error)
        }
    }



    const LoadPage = async () => {
        try {
            setQuizData(await GetChallenges(current))
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    }, [])



    try {
        return (
            <div className={`Challenges Challenges-${readyToEvaluate ? isCorrect === true ? 'Good' : 'Bad' : ''}`}>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <p className="Challenges-Text">{isCorrect === null ? quizData?.instruction : isCorrect ? '¡Correcto!' : '¡Incorrecto!'}</p>
                        
                        {quizData && (
                            <div className="Challenges-Options">
                                {quizData.options.map((option, index) => (
                                    <img
                                        key={index}
                                        src={option.urlImage}
                                        alt={`Opción #${index + 1}`}
                                        className={`Challenges-Option
                                            ${selectedOptionIndex !== null && selectedOptionIndex !== index ? 'Challenges-Option-Unselected' : ''}
                                            ${readyToEvaluate && selectedOptionIndex === index && option.isCorrect ? 'Challenges-Option-Good' : ''}
                                            ${readyToEvaluate && selectedOptionIndex === index && !option.isCorrect ? 'Challenges-Option-Bad' : ''}`}
                                        onClick={() => Validate(option.isCorrect, index)} />
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        )        
    } catch (error) {
        console.log(error)
    }
}

export { Challenges };