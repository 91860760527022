import { useState, useEffect } from "react"


//Componentes comunes generales
import { Loading } from "../../commons/components/Common/Loading"

//Componentes personalizados del proyecto
import { Teaching } from "./Teaching/Teaching"
import { Header } from "../../commons/components/Panel/Header"
import { Activity } from "./Activity/Activity"
import { Exam } from "./Exam/Exam"


const Viewer = ({ beforeView, nextView, additionalNextView, current }) => {
    const [activeComponent, setActiveComponent] = useState('') // Estado para controlar el componente activo
    const [isLoading, setIsLoading] = useState(true)

    
    const LoadPage = () => {
        try {
            if (current.type.toLowerCase() === 'teachingMaterial'.toLowerCase()) {
                setActiveComponent('teachingMaterial')
            } else if (current.type.toLowerCase() === 'activity'.toLowerCase()) {
                setActiveComponent('activity')
            } else if (current.type.toLowerCase() === 'exam'.toLowerCase()) {
                setActiveComponent('exam')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar



    try {
        return (
            <>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <Header beforeView={additionalNextView} />

                        {activeComponent.toLowerCase() === 'teachingMaterial'.toLowerCase() && <Teaching current={current} beforeView={beforeView} />}

                        {activeComponent.toLowerCase() === 'activity'.toLowerCase() && <Activity current={current} beforeView={beforeView} />}

                        {activeComponent.toLowerCase() === 'exam'.toLowerCase() && <Exam beforeView={beforeView} nextView={nextView} />}
                    </>
                )}
            </>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Viewer };