import { useEffect, useState } from "react"


//Funciones personalizadas del proyecto
import { GetVideos } from "../../../../utilities/BL/Viewer/Teaching/Concepts"


const Videos = ({ current }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [videosData, setVideosData] = useState({})


    const LoadPage = async () => {
        try {
            setVideosData(await GetVideos(current))
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    }, [])



    try {
        return (
            <div className="Videos">
                {isLoading ? (
                    <p>Cargando...</p>
                ) : (
                    <video className="Video-Video" src={videosData.urlVideo} controls>Tu navegador no soporta los elementos de vídeo</video>
                )}
            </div>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Videos };