const Checkbox = ({ id, checked, onChange, label }) => {
    return (
      <div className='loginForm-Checkbox'>
        <input type="checkbox" className='loginForm-Check' id={id} checked={checked} onChange={onChange} />
        <label className="loginForm-Label" htmlFor={id}>
          {label}
        </label>
      </div>
    )
  }
  
  export { Checkbox };