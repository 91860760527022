import React from 'react'


const QuestionDrafting = ({ drafting }) => {
  // Expresión regular para encontrar palabras precedidas por #
    const hashtagRegex = /(#[\w:]+[\w\d():]+|#\([^)]+\))+/g

    // Función para dividir el texto en palabras y resaltar las que tienen hashtag
    const highlightHashtags = (drafting) => {
        // Verificar si 'text' tiene un valor antes de llamar a split()
        if (!drafting) {
            return null // O puedes retornar un mensaje de error o un valor predeterminado
        }

        const parts = drafting.split(hashtagRegex)

        return parts.map((part, index) => {
            if (hashtagRegex.test(part)) {
                // Eliminar el # y resaltar la palabra
                const highlightedWord = part.slice(1)
                return <span key={index} className='Results-Question-Drafting-Hightlight'>{highlightedWord} </span>
            } else {
                return <span key={index}>{part}</span>
            }
        })
    }



    try {
        return (
            <p className='Results-Question-Drafting'>Pregunta: {highlightHashtags(drafting)}</p>
        )
    } catch (error) {
        console.log(error)
    }
}

export { QuestionDrafting };