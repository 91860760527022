import { useState } from 'react'

//Componentes comunes generales
//import { ProgressBar } from '../../commons/components/Common/ProgressBar'

//Componentes personalizados
import { Competence } from './Competence'
//import { TopicButton } from './TopicButton'
import { SearchInput } from './SearchInput'

//Funciones personalizadas del proyecto
import { SetExamToShow } from './../../utilities/BL/Students'


function Topics({ topics, indexCourse, nextView, current, setCurrent, result, isFirstTimeToTakeExam, setIsFirstTimeToTakeExam }) {
    const [isActive, setIsActive] = useState(false)
    

    const StartExam = async (index) => {
        try {    
            setCurrent(await SetExamToShow(indexCourse, index))
            setIsFirstTimeToTakeExam(false)
            nextView()
        } catch (error) {
            console.log(error)
        }
    }

    const ToggleList = (event) => {
        setIsActive(!isActive) // Invertir el estado
        const button = event.currentTarget // Obtener el botón clickeado
        const parent1 = button.closest('div').parentNode // Buscar Padre del botón
        const parent2 = parent1.closest('div').parentNode // Buscar Hijo del botón
        parent2.classList.toggle('Topics-Show') // Agregar/quitar clase
    }

    const ToggleListDoubleClick = (event) => {
        setIsActive(!isActive) // Invertir el estado
        event.currentTarget.classList.toggle('Topics-Show') // Agregar/quitar clase
    }

    //Falta completar esto
    //Situaciones por abarcar:
    //Si es la primera vez, debo poner activo el primer Examen y abrir el listado de este. ✅
    //Si viene de otra pantalla y ya tiene current, lo debo devolver a la competencia donde se quedó (Abrir Examen y posicionar en Tema). 
    //Si viene de otra pantalla y NO tiene current, debo poner activo el primer o siguiente Examen NO terminado y abrir el listado de este. 

    return (
        <>            
            {topics !== undefined && topics.length > 0 && (
                topics.map((topic, index) => (
                    // <Topics key={index} className={} indexCourse={course.indexCourse} infoTopic={topic} nextView={nextView} />
                
                    <div key={index} className={`Topics ${topics.length === 1 ? 'Topics-Show' : ''}`} onDoubleClick={ToggleListDoubleClick}>
                        <div className={`Topic ${index === 0 ? 'Topic-Info-Active' : ''}`}>
                            <div className={'Topic-Info'}>
                                {/* Nombre del tema */}
                                <p className='Topic-Title'>{topic.topic}</p>
                                {/* Porcentaje completado del tema */}
                                {/* <ProgressBar percentageCompleted={topic.percentageCompleted} /> */}
                            </div>

                            {/* Botón para mostrar u ocultar listado */}
                            <div className='Topic-Buttons'>
                                {isFirstTimeToTakeExam && (
                                    <button type='button' className='Topic-Buttons-Exam' onClick={() => StartExam(index)}>Iniciar examen</button>
                                )}


                                {/* <TopicButton /> */}
                                <button type='button' className='Topic-Buttons-List' onClick={ToggleList}><i className={`bi bi-caret-${isActive ? 'up' : 'down'}-fill`}></i></button>
                            </div>
                        </div>

                        <ul className='Competences'>
                            <SearchInput placeholder={'Buscar tema...'} customClassName={'Exam-Search'} current={current} indexExam={index} setCurrent={setCurrent} nextView={nextView} />

                            {topic.competences.map((competence, indexTopic) => (
                                <Competence key={indexTopic} indexCourse={indexCourse} indexTopic={index} infoCompetence={competence} nextView={nextView} setCurrent={setCurrent} />
                            ))}
                        </ul>
                    </div>
                ))
            )}
        </>
    )
}

export { Topics };