import { React, useState, useEffect } from 'react'

//Componentes personalizados del proyecto
//import { TileTopic } from './TileTopic'
import { Topics } from './Topics'
//import { SearchInput } from './SearchInput'

//Componentes comunes del proyecto
//import { Section } from '../Dashboard/Section'

//Componentes comunes generales
import { Header } from './../../commons/components/Panel/Header'
import { Loading } from './../../commons/components/Common/Loading'
//import { Menu } from './../../commons/components/Menu/Menu'
//import { MenuDown } from './../../commons/components/Menu/MenuDown'

//Funciones personalizadas del proyecto
import { GetCourse } from '../../utilities/BL/Topics'


const Course = ({ beforeView, additionalBeforeView, nextView, current, setCurrent, result, isFirstTimeToTakeExam, setIsFirstTimeToTakeExam }) => {
    const [isLoading, setIsLoading] = useState(true) // Estado qude carga
    const [course, setCourse] = useState({})
    

    const LoadPage = async () => {
        try {
            const tmpCourse = await GetCourse()
            setCourse(tmpCourse)
            //console.log(tmpCourse)
        } catch (error) {
            console.log(error)
        } finally {
            //console.log(course)
            setIsLoading(false) // Marcar la carga como finalizada
        }
    }

    useEffect(() => {
        LoadPage()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar



    try {
        return (
            <>
                <Header beforeView={additionalBeforeView} />

                {isLoading ? (
                    <Loading />
                ) : (
                    <main className='Container Course'>
                        <button type='button' className='Button-Return' onClick={() => beforeView()}><i className="bi bi-arrow-left"></i></button>

                        <div className='Course-Info'>
                            <div className='Course-Text'>
                                <p className='Course-Name'>{`${course.nameCourse} nivel ${course.level}`}</p>
                                <p className='Course-Type'>{course.typeCourse}</p>
                            </div>
                        </div>

                        <Topics topics={course.topics} indexCourse={course.indexCourse} nextView={nextView} current={current} setCurrent={setCurrent} result={result} isFirstTimeToTakeExam={isFirstTimeToTakeExam} setIsFirstTimeToTakeExam={setIsFirstTimeToTakeExam} />
                    </main>
                )}
                {/* <Menu />
                <MenuDown /> */}
            </>
        )
    } catch (error) {
        console.log(error)
    }    
}

export { Course };



//--- Página Topics ---//
// <>
//   <Header schoolId={"ZZZZ-ZZZZ"} schoolName={"Nombre del instituto"} />

//   <main className='container mt-5'>
//     <div className='row courseInfo'>
//       <p className='nameCourse'>{`${infoCourse.Name} level ${infoCourse.Level}`}</p>
//       <p className='typeCourse'>{infoCourse.Type}</p>
//     </div>

//     <Section textSection={'Continúa donde te quedaste'} dismiss={true} classNameSection={'mt-5'}>
//       <TilePanel elements={tileTopicElements} Component={TileTopic} />
//     </Section>

//     <Section textSection={'Temario'} classNameSection={'mt-5'}>
//       <div className='w-100'>
//         <SearchInput placeholder={'Search topic...'} customClassName={'mt-4 mb-5'} />

//         {infoCourse.Topics !== undefined && infoCourse.Topics.length > 0 && (
//           infoCourse.Topics.map((topic, index) => (
//             <Topic key={index} infoTopic={topic} />
//           ))
//         )}
//         </div>
//     </Section>
//   </main>

//   <Menu />
//   <MenuDown />
// </>