import { useEffect, useState } from "react"

//Funciones personalizadas del proyecto
import { GetCorrectAnswers, GetQuiz } from '../../../../utilities/BL/Viewer/Teaching/Concepts'


const Quizzes = ({ current }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [questions, setQuestions] = useState([])
    const [selectedAnswers, setSelectedAnswers] = useState({})
    const [showResults, setShowResults] = useState(false)
    const [correctAnswers, setCorrectAnswers] = useState(0)
    const [resultsSent, setResultsSent] = useState(false)


    const Validate = () => {
        try {
            if (showResults === false) {
                setCorrectAnswers(GetCorrectAnswers(questions,selectedAnswers))
                setShowResults(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const Retry = async () => {
        try {
            setSelectedAnswers({})
            setShowResults(false)
            setQuestions(await GetQuiz(current))
        } catch (error) {
            console.log(error)
        }
    }

    const Record = () => {
        try {
            if (resultsSent === false) {
                //console.log('Puntaje registrado')
                setResultsSent(true)
            }
        } catch (error) {
            console.log(error)
        }
    }



    const handleOptionChange = (questionIndex, optionText) => {
        if (showResults === false) {
            setSelectedAnswers({
                ...selectedAnswers,
                [questionIndex]: optionText
            })
        }
    }



    
    const LoadPage = async () => {
        try {
            setQuestions(await GetQuiz(current))
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    }, [])
    

    
    try {
        return (
            isLoading ? (
                <p>Cargando...</p>
            ) : (
                <div className="Quizzes">
                    {resultsSent ? (
                        <div className="Quiz-Question-Question-ResultsSent">
                            <i className="bi bi-check-circle Quiz-Question-Question-ResultsSent-Icon"></i>

                            <p>Tus resultados se han registrado con éxito.</p>
                        </div>
                    ) : (
                        <>
                            {/* Renderizar las preguntas */}
                            {questions.questions.map((question, index) => (
                                <div key={index} className={`Quiz-Question-Question ${!selectedAnswers[index] && showResults ? 'Quiz-Question-Question-Wrong' : ''}`}>
                                    <p className="Quiz-Question-Text">{question.question}</p>

                                    {question.options.map((option, optionIndex) => (
                                        <label key={optionIndex} className='Quiz-Question-Option-Label'>
                                            {/* <input type="radio" name={`Quiz-Question-${index}`} className="Quiz-Question-Option-Radio" value={option.text} checked={selectedAnswers[index] === option.text} onChange={() => handleOptionChange(index, option.text)} disabled={showResults} />{option.text} */}
                                            <input type="radio" name={`Quiz-Question-${index}`} className="Quiz-Question-Option-Radio" value={option.text} checked={selectedAnswers[index] === option.text} onChange={() => handleOptionChange(index, option.text)} />{option.text}
                                        </label>
                                    ))}

                                    {/* Mostrar mensaje de resultado para cada pregunta */}
                                    {showResults && (
                                        <p className={`Quiz-Question-Result-${selectedAnswers[index] === question.options.find(option => option.isCorrect)?.text ? 'Good' : 'Bad'}`}>
                                            {selectedAnswers[index] === question.options.find(option => option.isCorrect)?.text
                                                ? question.messageGood
                                                : question.messageBad}
                                        </p>
                                    )}
                                </div>
                            ))}

                            {/* Mostrar resultados generales y botones */}
                            {showResults ? (
                                <>
                                    <p className="Quiz-Question-CorrectAnswers">Respuestas correctas: {correctAnswers} de {questions.questions.length} ({((correctAnswers / questions.questions.length) * 100).toFixed(2)}%)</p>

                                    <div className="Quiz-Question-Buttons">
                                        <button type="button" className="Quiz-Question-Buttons-Retry" onClick={Retry}><i class="bi bi-arrow-counterclockwise"></i> Reintentar</button>
                                        <button type="button" className="Quiz-Question-Buttons-Record" onClick={Record}><i class="bi bi-cloud-upload"></i> Registrar puntaje</button>
                                    </div>
                                </>
                            ) : (
                                <div className="Quiz-Question-Buttons">
                                    <button type="button" className="Quiz-Question-Buttons-Validate" onClick={Validate} disabled={showResults}><i class="bi bi-check"></i> Validar</button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )
        )
    } catch (error) {
        console.log(error)
    }
}

export { Quizzes };







{/* <div className="Quiz-Question-Question">
    <p className="Quiz-Question-Text">¿Cómo puedes escribir texto en las celdas?</p>

    <label className="Quiz-Question-Option-Label">
        <input type="radio" name="Quiz-Question-One" className="Quiz-Question-Option-Radio" value='' /> Utilizando el teclado de mi computadora
    </label>

    <label className="Quiz-Question-Option-Label">
        <input type="radio" name="Quiz-Question-One" className="Quiz-Question-Option-Radio" value='' /> Utilizando una lámpara
    </label>

    <label className="Quiz-Question-Option-Label">
        <input type="radio" name="Quiz-Question-One" className="Quiz-Question-Option-Radio" value='' /> Utilizando un lápiz
    </label>

    <p className="Quiz-Question-Result-Good">¡Correcto!</p>

    <p className="Quiz-Question-Result-Bad">Incorrecto.

    Una de las dos formas principales para escribir texto es mediante el uso del teclado de la computadora.</p>
</div>


<div className="Quiz-Question-Question">
    <p className="Quiz-Question-Text">¿Cómo puedes hacer una lista de los animales que más te gustan?</p>

    <label className="Quiz-Question-Option-Label">
        <input type="radio" name="Quiz-Question-Two" className="Quiz-Question-Option-Radio" value='' /> Coloreando un libro
    </label>

    <label className="Quiz-Question-Option-Label">
        <input type="radio" name="Quiz-Question-Two" className="Quiz-Question-Option-Radio" value='' /> Escribiendo sus nombres en las celdas
    </label>

    <label className="Quiz-Question-Option-Label">
        <input type="radio" name="Quiz-Question-Two" className="Quiz-Question-Option-Radio" value='' /> Resolviendo acertijos
    </label>

    <p className="Quiz-Question-Result-Good">¡Correcto!</p>

    <p className="Quiz-Question-Result-Bad">Incorrecto.

    Para crear una lista de los animales que más te gustan, lo primero es escribir sus nombres en las celdas de la hoja de cálculo.</p>
</div>

<p className="Quiz-Question-CorrectAnswers">Respuestas correctas: 1 de 2 (100.00%)</p>

<div className="Quiz-Question-Buttons">
    <button type="button" className="Quiz-Question-Buttons-Validate" onClick={() => Validate()}>Verificar</button>
    <button type="button" className="Quiz-Question-Buttons-Retry" onClick={() => Retry()}>Reintentar</button>
    <button type="button" className="Quiz-Question-Buttons-Record" onClick={() => Record()}>Registrar puntaje</button>
</div>*/}