import React, { useState, useEffect } from 'react'


const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      // Mostrar el botón si se ha desplazado más de un cierto umbral (por ejemplo, 200 píxeles)
      setIsVisible(window.pageYOffset > 100)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Desplazamiento suave
    })
  }

  try {
    return (
        <button type='button' className={`GoToTop ${isVisible ? 'GoToTop-Visible' : ''}`}  onClick={scrollToTop}>
            <i className="bi bi-arrow-up-short GoToTop-Icon"></i>
        </button>
        )
    } catch (error) {
    console.log(error)
    }
}

export { GoToTop };