//Componentes comunes generales
import { Button } from './../../../commons/components/Common/Button'

//Funciones personalizadas del proyecto
import { SetTopicToShow, SetActivityToShow, SetIndexCourseAndTopic } from '../../../utilities/BL/Students'


function TileStudent({ elements, ...props }) {
    const { nextView, setCurrent, isResult } = props


    const ShowMoveIcon = (e) => {
        try {
            e.target.parentNode.classList.remove('HideMoveButton')
        } catch (error) {
            console.log(error)
        }
    }
    
    const HideMoveIcon = (e) => {
        try {
            e.target.parentNode.classList.add('HideMoveButton')
        } catch (error) {
            console.log(error)
        }
    }
    


    //----- Funciones directas a mostrar recurso en Viewer -----//
    //Función para cuando es materialTeaching
    const ShowTopic = async (indexCourse, indexExam, indexTopic) => {
        //console.log(`indexCourse: ${indexCourse} - indexExam ${indexExam} - indexTopic ${indexTopic}`)
        setCurrent(await SetTopicToShow(indexCourse, indexExam, indexTopic))
        nextView()
    }

    //Función para cuando es actividad
    //Falta completar esto
    const ShowActivity = async (indexCourse, indexTopic, indexActivity) => {
        try {
            //console.log(`indexCourse: ${indexCourse} - indexTopic ${indexTopic} - indexActivity ${indexActivity}`)
            setCurrent(await SetActivityToShow(indexCourse, indexTopic, indexActivity))
            nextView()
        } catch (error) {
            console.log(error)
        }
    }

    //Función para cuando es exam
    //Falta completar esto
    const ShowExam = async () => {
        try {
            console.log('ShowExam')
            //setCurrent()
            nextView()
        } catch (error) {
            console.log(error)
        }
    }
    //----- Funciones directas a mostrar recurso en Viewer -----//


    
    //----- Funciones para mostrar el curso en Course -----//
    const SetCourseToShow = async (indexCourse) => {
        try {
            //console.log(`indexCourse: ${indexCourse}`)
            setCurrent(await SetIndexCourseAndTopic(indexCourse, ''))
            nextView()
        } catch (error) {
            console(error)
        }
    }
    //----- Funciones para mostrar el curso en Course -----//

    

    return (
        <>
            {/* Validar que el arreglo tenga elementos por imprimir */}
            {elements !== undefined && elements.length > 0 ? (
                //Recorrer los elementos del arreglo
                elements.map((element, index) => (
                    element && (
                        <div key={index} className={`Tiles HideMoveButton ${element.tileClassName ? element.tileClassName : ''}`}>
                            {/* Botón mover */}
                            <Button className={'Tiles-ButtonMoveTile'} iconClass={'bi bi-three-dots-vertical'} />

                            {element.type.toLowerCase() === 'teachingMaterial'.toLowerCase() ? (
                                <a className={`Tiles-StudentTile--M--Triple ${index === 0 && element.activeElementClass && element.activeElementClass !== '' ? element.activeElementClass : ''}`} onMouseOver={ShowMoveIcon} onMouseOut={HideMoveIcon} onClick={() => ShowTopic(element.indexCourse, element.indexExam, element.indexTopic)}>
                                    <div className='Tile-Info'>
                                        {element.tileIconClass && element.tileIconClass !== '' && (
                                            <figure className='Tile-Figure'>
                                                <i className={`Tile-Icon ${element.tileIconClass}`}></i>
                                            </figure>
                                        )}
                                        
                                        <div className='Tile-ContainerText'>
                                            <span className='Tile-Text'>{element.competence}</span>
                                            <span className='Tile-Text'>{element.nameCourse}</span>
                                            <span className='Tile-Text'>{element.typeCourse}</span>
                                        </div>
                                    </div>

                                    {/* Botón */}
                                    <i className='bi bi-box-arrow-up-right Tile-Icon-GoTo'></i>
                                </a>  
                            ) : (
                                <>
                                    {/* //Falta completar esto */}
                                    {element.type.toLowerCase() === 'activity'.toLowerCase() && (
                                        <>
                                            <p key={index} onClick={() => ShowActivity(element.indexCourse, element.indexTopic, element.indexActivity)}>activity</p>
                                        </>
                                    )}

                                    {/* //Falta completar esto */}
                                    {element.type.toLowerCase() === 'exam'.toLowerCase() && (
                                        <>
                                            <p key={index} onClick={() => ShowExam()}>exam</p>
                                        </>
                                    )}

                                    {element.type.toLowerCase() === 'course'.toLowerCase() && (
                                        <a className={`Tiles-StudentTile--M--Double ${index === 0 && element.activeElementClass && element.activeElementClass !== '' ? element.activeElementClass : ''}`} onMouseOver={ShowMoveIcon} onMouseOut={HideMoveIcon} onClick={() => SetCourseToShow(element.indexCourse)}>
                                            <div className='Tile-Info'>
                                                {element.tileIconClass && element.tileIconClass !== '' && (
                                                    <figure className='Tile-Figure'>
                                                        <i className={`Tile-Icon ${element.tileIconClass}`}></i>
                                                    </figure>
                                                )}
                                                
                                                <div className='Tile-ContainerText'>
                                                            <span className='Tile-Text'>{element.nameCourse}</span>
                                                            <span className='Tile-Text'>{element.typeCourse}</span>
                                                </div>
                                            </div>

                                            {/* Botón */}
                                            <i className='bi bi-box-arrow-up-right Tile-Icon-GoTo'></i>
                                        </a>
                                    )}
                                </>
                            )}
                        </div>
                    )
                ))
            ) : (
                isResult && (
                    <div className='Tiles HideMoveButton'>
                        {/* Botón mover */}
                        <Button className={'Tiles-ButtonMoveTile'} iconClass={'bi bi-three-dots-vertical'} />
                        
                        <a className='Tiles-StudentTile--M--Double' onClick={() => nextView()}>
                            <div className='Tile-Info'>
                                <figure className='Tile-Figure'>
                                    <i className='Tile-Icon bi bi-list-check'></i>
                                </figure>
                            
                                <div className='Tile-ContainerText'>
                                    <span className='Tile-Text'>Examen Demo</span>
                                    <span className='Tile-Text'>Certificación</span>
                                </div>
                            </div>
                            
                            <i className='bi bi-box-arrow-up-right Tile-Icon-GoTo'></i>
                        </a>
                    </div>
                )
            )}
        </>
    )
}

export { TileStudent };